.sponsorImages {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;


}

.sponsorImage {
    max-width: 150px;
    object-fit: contain;
    margin: 10px;
}